import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ClientComponent } from './client.component';
import { SignupPopupComponent } from './component/signup-popup/signup-popup.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import {SharedModule} from "../shared/shared.module";


@NgModule({
  declarations: [ClientComponent, SignupPopupComponent],
    imports: [
        CommonModule,
        ClientRoutingModule,
        FormsModule,
        NgxMaskModule,
        SharedModule,
    ],
  entryComponents: [SignupPopupComponent]
})
export class ClientModule { }
