import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-modal-status',
  templateUrl: './modal-status.component.html',
  styleUrls: ['./modal-status.component.scss']
})
export class ModalStatusComponent implements OnInit {
  @Input() status: boolean;
  constructor(public activeModal: NgbActiveModal, private router: Router,  private location: Location) { }

  ngOnInit() {
    /*activeModal.dismiss('Cross click')"*/
  }
  closeModal() {
      this.activeModal.close();
      this.location.replaceState(location.pathname);
  }
}
