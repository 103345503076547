import { Deserializable } from 'src/app/shared/model/deserializable';

export class User implements Deserializable {
  auth_key: string;
  client_id: number;
  created_at: number;
  email: string;
  id: number;
  is_admin: number;
  password_hash: string;
  password_reset_token: string;
  phone: string;
  status: number;
  updated_at: number;
  username: string;
  verification_token: any;
  _pgdSid: any;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
