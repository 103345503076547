import { Deserializable } from 'src/app/shared/model/deserializable';
import { Tariff } from 'src/app/pages/tariffs/model/tariff';
import { TariffSetting } from 'src/app/pages/tariffs/model/tariff-setting';

export class Client implements Deserializable {
    balance: string;
    bonus: any;
    bonus_balance: any;
    branch_id: any;
    country_lang: string;
    country_req: string;
    created_at: number;
    id: number;
    is_delete: number;
    title: string;
    updated_at: number;
    clientFormLang: string;
    interface_lang: string;
    langSlip: string;
    tariff: Tariff;
    settings: TariffSetting;


    deserialize(input: any) {
        Object.assign(this, input);
        if (this.interface_lang === 'EN') {
          this.langSlip = 'en';
          this.clientFormLang = 'EN';
        } else {
          this.langSlip = 'ru';
          this.clientFormLang = 'RU';
        }
        return this;
    }
}
