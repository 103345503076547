import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {ToastrService} from 'ngx-toastr';
import {whileComponentNotDestroyed} from '../../shared/decorators/component-destroy-observer/component-destroy-observer';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CoreService} from "../../core/service/core.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    loader = false;
    params;

    constructor(private authService: AuthService,
                private toastr: ToastrService,
                private router: Router,
                private coreService: CoreService,
                private translateService: TranslateService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.switchedRouterBeckend();
        this.loadParams();
    }

    onSubmit() {
        this.loader = true;
        this.authService.login(this.model, this.params)
            .pipe(whileComponentNotDestroyed(this))
            .subscribe(
                result => {
                    if (result.success) {
                        this.toastr.success(this.translateService.instant('AUTH.Successful_login'), this.translateService.instant('SETTING.Successfully'));
                        this.router.navigate(['/statistics/list']);
                    } else {
                        this.toastr.error(this.translateService.instant('SETTING.Error'), this.translateService.instant('AUTH.Enter_the_correct_data'));
                    }
                    this.loader = false;
                },
                (error) => {
                    this.toastr.error(this.translateService.instant('SETTING.Error'), this.translateService.instant('AUTH.Enter_the_correct_data'));
                    this.loader = false;
                });
    }

    switchedRouterBeckend() {
        this.route.queryParams.subscribe(params => {
            if (params.auth_key) {
                this.userInfo(params.auth_key);
            }
        });
    }


    userInfo(token) {
        this.authService.userFromAdmin(token)
            .pipe(whileComponentNotDestroyed(this))
            .subscribe(
                result => {
                    localStorage.setItem('token', result.data.token);
                    this.router.navigate(['/statistics/list']);
                },
                (error) => {

                });
    }

    loadParams() {
        this.coreService.loadParams()
            .pipe(whileComponentNotDestroyed(this))
            .subscribe(
                result => {
                    if (this.coreService._getParams()) {
                        localStorage.removeItem('params');
                    }
                    this.coreService._setParams(result);
                    console.log(result);
                    this.params = result;
                },
                (error) => {
                    /*console.log(error);*/
                    /*error.foreach(item => {
                        this.toastr.error(item.message, 'Завершено с ошибкой');
                    });*/
                });
    }
}
