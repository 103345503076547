import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { whileComponentNotDestroyed } from './shared/decorators/component-destroy-observer/component-destroy-observer';
import { AuthService } from './auth/service/auth.service';
import { User } from 'src/app/auth/model/user';
import { CoreService } from './core/service/core.service';
import * as moment from 'moment';
import { CustomDatepickerI18n } from 'src/app/shared/_helpers/datapickeri18/datepicker-i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CustomDatepickerI18n]
})
export class AppComponent implements OnInit {
  title = 'frontend-new';
  user: User;
  params: any;

  constructor (private router: Router,
               private activatedRoute: ActivatedRoute,
               private titleService: Title,
               private coreService: CoreService,
               private authService: AuthService,
               private i18: CustomDatepickerI18n,
               public translate: TranslateService) {
    /*this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            console.log(child.snapshot.data);
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe( (data: any) => {
      if (data) {
        console.log('data'data);
        this.titleService.setTitle(data + ' - UnApps.io');
      }
    });*/

    translate.addLangs(['en', 'ru']);
    translate.setDefaultLang('ru');


    /*const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ru/) ? browserLang : 'ru');*/
  }

  ngOnInit() {
    this.paramsTitle();
    this.loadParams();
    this.setLang();
  }
  paramsTitle() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let title = '';
        const child = this.activatedRoute.firstChild;
        if ((child.snapshot.firstChild !== null ) && child.snapshot.firstChild.data['title']) {
          title =  child.snapshot.firstChild.data['title'];
        } else {
          child.data.subscribe( item => {
            title = item.title + appTitle;
          });
        }
        return title;
      })
    ).subscribe((ttl: string) => {
      this.titleService.setTitle(ttl + ' - UnApps.io');
    });
  }

  loadParams() {
    this.coreService.loadParams()
        .pipe(whileComponentNotDestroyed(this))
        .subscribe(
            result => {
              if (this.coreService._getParams()) {
                localStorage.removeItem('params');
              }
              this.coreService._setParams(result);
              this.coreService.params.next(result);
              this.params = result;
              this.setLang();
              this.routing();

            },
            (error) => {
              /*console.log(error);*/
              /*error.foreach(item => {
                this.toastr.error(item.message, 'Завершено с ошибкой');
              });*/
            });

  }

  setLang() {
    this.authService._getCurrentClient().subscribe(item => {
      if (item) {
        if (item.interface_lang === 'EN') {
          this.translate.use('en');
          moment.locale('en');
        } else {
          if (item.interface_lang === 'RU') {
            this.translate.use('ru');
            moment.locale('ru');
          } else {
            const browserLang = this.translate.getBrowserLang();
            this.translate.use(browserLang.match(/en|ru/) ? browserLang : 'ru');
          }
        }
      } else {
        if (this.params) {
          this.translate.use(this.params.lang);
        }
      }
    });
  }

  routing() {
    /*console.log(this.params)*/
    if (this.params.isBox === 'true' && this.params.isInstall === 'false') {
      this.router.navigate(['/install']);
    } else if (this.params.isBox === 'true' && this.params.isInstall === 'true') {
      /*this.router.navigate(['/']);*/
    } else {
      //this.router.navigate(['/statistics']);
    }
    /*if () {

    }*/
  }
}
