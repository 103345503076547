import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstallComponent } from '../install/install.component';
import { ClientComponent } from './client.component';
import { AppComponent } from '../app.component';


const routes: Routes = [
  {
    path: 'connectors',
    data: {title: 'Приложения'},
    loadChildren : () => import('./connector/connector.module').then(m => m.ConnectorModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
