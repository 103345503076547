import { Deserializable } from 'src/app/shared/model/deserializable';

export class AuthorizationProvider implements Deserializable {
  created_at: number;
  id: number;
  is_delete: number;
  logo: string;
  provider: string;
  type: string;
  updated_at: number;
  title: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
