import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchForObject'
})
export class SearchForObjectPipe implements PipeTransform {

  transform(value: any, queryString): any {
    if (value === null) {
      return null;
    }
    if (value === '') {
      return null;
    }

    if (queryString !== undefined) {
      return  value.filter(item => {
        if (item.key.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) {
          return value;
        }
      });
    } else {
      return value;
    }
  }

}
