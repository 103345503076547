import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { Task } from 'src/app/pages/task/model/task';
import { CoreService } from '../../../core/service/core.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  params: any;
  public currentOpenTrigger$ = new Subject<number>();
  public currentPage$ = new BehaviorSubject<number>(1);
  constructor( private http: HttpClient, private coreService: CoreService ) {
    this.params = this.coreService._getParams();
  }

  loadTask(params = {}): Observable<any> {
    const url = `${this.params.api}/tasks`;
    return this.http.post<any>(url, params, {observe: 'response' as 'body'})
      .pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  deleteAllTask(params = ''): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: params
      },
    };
    const url = `${this.params.api}/tasks/pack`;
    return this.http.delete<any>(url, options).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  editAllTask(params = {}): Observable<any> {
    const url = `${this.params.api}/tasks/pack`;
    return this.http.patch<any>(url, params).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  editTask(task): Observable<Task> {
    const data = {
      name: task.name,
      description: task.description,
    };
    const url = `${this.params.api}/tasks/${task.id}`;
    return this.http.patch<any>(url, data).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  saveSource(task): Observable<Task> {
    const data = {
      start_event: task.start_event,
      source: task.source
    };
    const url = `${this.params.api}/tasks/${task.id}`;
    return this.http.patch<any>(url, data).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  taskById(id: string): Observable<any> {
    const url = `${this.params.api}/tasks/${id}?expand=history.transactions`;
    return this.http.post<any>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  taskByIdNoRelation(id: string): Observable<any> {
    const url = `${this.params.api}/tasks/${id}`;
    return this.http.post<any>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  createTask(data): Observable<any> {
    const url = `${this.params.api}/tasks/create`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
    );
  }

  testTask(data, idTask: number = 0, step: number = 0, urlClud): Observable<string> {
      console.log(data)
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const url = `${urlClud}/compiler/tester.php?taskid=${idTask}&step=${step}`;
    return this.http.post<string>(url, data, {
      params: {},
      headers,
      responseType: 'text' as 'json'
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  compilerTask(idTask: string, cloudApi): Observable<any> {
    /*const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');*/
    const url = `${cloudApi}/compiler/make.php?type=task&taskid=${idTask}`;
    return this.http.get<string>(url, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

    compilerTaskBox(task, cloudApi): Observable<any> {
        /*const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');*/
        const url = `${cloudApi}/compiler/make.php?type=box&taskid=${task.id}`;
        return this.http.post<string>(url, task.source, {
            params: {},
        }).pipe(
            map(result => {
                return result;
            }),
            catchError(error => throwError(error)),
            publishLast(),
            refCount()
        );
    }

  compileSaveFile(file: string): Observable<any> {
    /*const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');*/
    const url = `${this.params.api}/tasks/save-file`;
    return this.http.post<string>(url, {file},{
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }



  loadValueFields(fieldid, data): Observable<any> {
    const url = `${this.params.cloudApi}/compiler/selector.php?fieldid=${fieldid}`;
    return this.http.post<any>(url, data)
        .pipe(
            map(result => {
              return result;
            }),
            catchError(error => throwError(error)),
            publishLast(),
            refCount()
        );
  }
}
