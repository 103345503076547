import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/service/auth.service';
import { CoreService } from 'src/app/core/service/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  params: any;
  constructor( private router: Router, private auth: AuthService, private coreService: CoreService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.params = this.coreService._getParams();
    if (this.params.isBox === 'true' && this.params.isInstall === 'true') {
      /*this.router.navigate(['/statistics/list']);*/
      return true;
    } else {
      const token = localStorage.getItem('token');
      if (token) {
        // logged in so return true
        return true;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }
}
