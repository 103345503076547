import { Injectable, Provider } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Tariff } from 'src/app/pages/tariffs/model/tariff';
import { environment } from 'src/environments/environment';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { Requisite } from 'src/app/pages/tariffs/model/requisite';
import { Authorization } from 'src/app/pages/authorizations/model/authorization';
import { AuthorizationProvider } from '../model/authorization-provider';
import { ProviderConfig } from 'src/app/pages/authorizations/model/provider-config';
import { CoreService } from '../../../core/service/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public additionalAuth$ = new Subject<number>();
  private params = this.coreService._getParams();

  constructor( private http: HttpClient, private coreService: CoreService) { }

  loadAuthsets(params = {}): Observable<any> {
    const url = `${this.params.api}/authset`;
    return this.http.post<any>(url, params, {observe: 'response' as 'body'})
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
      );
  }

  loadAuthsetsById(id): Observable<Authorization> {
    const url = `${this.params.cloudBase}/authset/${id}`;
    return this.http.post<Authorization>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return new Authorization().deserialize(result);
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  loadProviders(params = {}): Observable<AuthorizationProvider[]> {
    const url = `${this.params.cloudBase}/authprov`;
    return this.http.post<AuthorizationProvider[]>(url, params, {
      params: {},
    }).pipe(
      map(result => {
        return result.map(item => new AuthorizationProvider().deserialize(item));
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  delAuth(params = ''): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: params
      },
    };
    const url = `${this.params.api}/authset/pack`;
    return this.http.delete<any>(url, options).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  loadConfigProvider(id): Observable<ProviderConfig> {
    const url = `${this.params.cloudBase}/authprov/config/${id}`;
    return this.http.post<ProviderConfig[]>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return new ProviderConfig().deserialize(result);
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  createAuth(data): Observable<any> {
    const url = `${this.params.auth}/auth/add.php`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  createOAuth(config): Observable<any> {
    const url = `${this.params.auth}/oauth.php?provider=${config.Provider}`;
    return this.http.post<any>(url, {}, {
      params: {},
    }).pipe(
        map(result => {

        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
    );
  }

    createOAuthLocal(auth): Observable<any> {
        const url = `${this.params.api}/authset/create`;
        return this.http.post<any>(url, auth, {
            params: {},
        }).pipe(
            map(result => {

            }),
            catchError(error => throwError(error)),
            publishLast(),
            refCount()
        );
    }

  /*loadTariffsByIdSales(id): Observable<Tariff> {
    const params = {};
    const url = `${this.params.api}/tariffs/${id}?expand=discount,packages`;
    return this.http.post<Tariff>(url, params, {
      params: {},
    }).pipe(
      map(result => {
        return new Tariff().deserialize(result);
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  createRequisite(data = {}): Observable<any> {
    const url = `${this.params.api}/bills/create`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        console.log(result);
        return new Requisite().deserialize(result);
        /!* return result.map(item => new Tariff().deserialize(item));*!/
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  editRequisite(data = {}, id: number): Observable<any> {
    const url = `${this.params.api}/bills/${id}`;
    return this.http.patch<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        console.log(result);
        return new Requisite().deserialize(result);
        /!* return result.map(item => new Tariff().deserialize(item));*!/
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  delRequisite(params = ''): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: params
      },
    };
    const url = `${this.params.api}/bills/pack`;
    return this.http.delete<any>(url, options).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }*/
}
