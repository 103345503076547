import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../core/guard/auth.guard';


const routes: Routes = [
  {path: '', component: PagesComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: {title: 'Страница'}, children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'statistics'
      },
      {
        path: 'history',
        data: {title: 'История'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./history/history.module').then(m => m.HistoryModule),
      },
      {
        path: 'help',
        data: {title: 'Помощь'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'setting',
        data: {title: 'Настройки'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./setting/setting.module').then(m => m.SettingModule),
      },
      {
        path: 'statistics',
        data: {title: 'Статистика'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'task',
        data: {title: 'Задачи'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./task/task.module').then(m => m.TaskModule),
      },
      {
        path: 'tariffs',
        data: {title: 'Тарифы и оплата'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./tariffs/tariffs.module').then(m => m.TariffsModule),
      },
      {
        path: 'account',
        data: {title: 'Счета'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'requisite',
        data: {title: 'Реквизиты'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./requisit/requisit.module').then(m => m.RequisitModule),
      },
      {
        path: 'authorizations',
        data: {title: 'Авторизации'},
        canActivate: [AuthGuard], canActivateChild: [AuthGuard],
        loadChildren : () => import('./authorizations/authorizations.module').then(m => m.AuthorizationsModule),
      },
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
