import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TokenInterceptor } from 'src/app/shared/_interceptor/token.interceptor';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MomentModule } from 'ngx-moment';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import 'moment/locale/ru';
import 'moment/locale/en-au';
import { ModalStatusComponent } from 'src/app/pages/authorizations/component/modal-status/modal-status.component';
import { AuthProviderLoginComponent } from 'src/app/pages/authorizations/component/auth-provider-login/auth-provider-login.component';
import { TagifyModule } from 'ngTagify';
import { InstallComponent } from './install/install.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomDatepickerI18n } from 'src/app/shared/_helpers/datapickeri18/datepicker-i18n';
import { ClientModule } from './client/client.module';
@NgModule({
  declarations: [
    AppComponent,
    ModalStatusComponent,
    AuthProviderLoginComponent,
    InstallComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AuthModule,
    NgbModule,
    PagesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
        {positionClass: 'toast-bottom-right'}
    ),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MomentModule.forRoot(),
    ReactiveFormsModule,
    TagifyModule.forRoot(),
    SharedModule,
    ClientModule
  ],
  providers: [AuthGuard,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalStatusComponent,
    AuthProviderLoginComponent
  ],
})
export class AppModule { }
