import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [NotFoundComponent, PagesComponent],
  imports: [
    PagesRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule,
  ]
})
export class PagesModule { }
