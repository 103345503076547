import { Deserializable } from 'src/app/shared/model/deserializable';
import { AuthorizationProvider } from 'src/app/pages/authorizations/model/authorization-provider';

export class Authorization implements Deserializable {
  id: number;
  client_id: number;
  url: string;
  logo: string;
  title: string;
  token: string;
  refresh_token: string;
  expiration_time: number;
  is_delete: number;
  created_at: number;
  updated_at: number;
  name: string;
  login: string;
  pass: string;
  active: boolean;
  provider: any;


  deserialize(input: any) {
    Object.assign(this, input);
    /*if (input.provider) {
      this.provider = new AuthorizationProvider().deserialize(input.provider);
    }*/
    return this;
  }
}
