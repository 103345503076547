import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MustMatchDirective } from './_helpers/must-match.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './components/loader/loader.component';
import { KeysPipe } from './pipe/keys.pipe';
import { MomentModule } from 'ngx-moment';
import { SearchForObjectPipe } from './pipe/search-for-object.pipe';
import { NoContentComponent } from './components/no-content/no-content.component';
import { LoginCloudComponent } from 'src/app/auth/login-cloud/login-cloud.component';
import { AuthModule } from 'src/app/auth/auth.module';
import { CustomDatepickerI18n } from 'src/app/shared/_helpers/datapickeri18/datepicker-i18n';



@NgModule({
  declarations: [HeaderComponent, SidebarComponent, MustMatchDirective, LoaderComponent, KeysPipe, SearchForObjectPipe, NoContentComponent, LoginCloudComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    MomentModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    NoContentComponent,
    FormsModule,
    MustMatchDirective,
    LoaderComponent,
    KeysPipe,
    SearchForObjectPipe,
    TranslateModule
  ],
  providers: [CustomDatepickerI18n],
  entryComponents: [
    LoginCloudComponent
  ],
})
export class SharedModule { }
