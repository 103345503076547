import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProviderField } from 'src/app/pages/authorizations/model/provider-field';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/pages/authorizations/service/authorization.service';
import { whileComponentNotDestroyed } from 'src/app/shared/decorators/component-destroy-observer/component-destroy-observer';
import { ProviderConfig } from 'src/app/pages/authorizations/model/provider-config';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/auth/model/client';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-auth-provider-login',
  templateUrl: './auth-provider-login.component.html',
  styleUrls: ['./auth-provider-login.component.scss']
})
export class AuthProviderLoginComponent implements OnInit {
  @Input() config: ProviderConfig;
  form: FormGroup;
  client: Client;

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private authService: AuthService,
              private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
    this.initForm(this.config.Fields);
    this.getCurrentClient();
  }

  getCurrentClient() {
    this.authService._getCurrentClient().subscribe(client => {
      this.client = client;
    });
  }

  initForm(fields) {
    this.form = this.fb.group(this.formFields(fields));
  }

  formFields(fields) {
    let empArr = {};
    for (let val of fields) {
      empArr[val.ID] = ['', !fields.Required ? Validators.required : null];
    }
    return empArr;
  }
  onSubmit() {
    // Here I would like to be able to access the values of the 'forms'
    let data = this.form.value;
    data.provider = this.config.Provider;
    this.authorizationService.createAuth(data)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          if (result.status) {
            this.toastr.success('Авторизация прошла успешно');
            this.authorizationService.additionalAuth$.next(result.id);
            this.closeModal(result);
          } else {
            this.toastr.error('Ошибка авторизации', result.message);
          }
        },
        (error) => {
          console.log(error);
        });
  }
  closeModal(result) {
    this.activeModal.dismiss();
  }
}
