import { Deserializable } from 'src/app/shared/model/deserializable';

export class ProviderField implements Deserializable {
  Help: any;
  ID: string;
  Name: any;
  Order: string;
  Required: boolean;
  Type: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
