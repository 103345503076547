import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { InstallService } from './service/install.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { whileComponentNotDestroyed } from '../shared/decorators/component-destroy-observer/component-destroy-observer';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CoreService } from '../core/service/core.service';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {
  currentStep = '1';
  loader = false;

  formStepOne: FormGroup;
  formStepTwo: FormGroup;
  @ViewChild('acc', {static: true}) acc: NgbAccordion;
  constructor(private installService: InstallService,
              private fb: FormBuilder,
              private toast: ToastrService,
              private router: Router,
              private coreService: CoreService,
              private translateService: TranslateService) { }

  ngOnInit() {
    this.buildFormStep();
    this.currentStep = this.installService.getStep() || '1';
  }

  bdInstall() {
    this.loader = true;

    // stop here if form is invalid
    if (this.formStepOne.invalid) {
      return;
    }
    const data = Object.assign({}, this.formStepOne.value);
    data.front = window.location.origin;
    this.installService.step_one(data)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          this.loader = false;
          if (result.success) {
            this.currentStep = '2';
            this.acc.activeIds = 'toggle-2';
            this.toast.success(this.translateService.instant('SETTING.Successfully'));
            this.currentStep = '2';
            this.acc.activeIds = 'toggle-2';
            this.installService.setStep('2');
            const params = Object.assign({}, this.coreService._getParams());
            this.coreService._delParams();
            params.api = this.formStepOne.value.domen;
            this.coreService._setParams(params);
          } else {
            for (let error in result.errors) {
              if (!result.errors[error].success) {
                this.toast.error(error, result.errors[error].errors.message);
              }
            }
          }
        },
        (error) => {
          console.log(error);
          this.loader = false;
          this.toast.error(this.translateService.instant('SETTING.Error'));
        });
  }

  cloudAccountInstall() {
    this.loader = true;
   /* this.currentStep = '3';
    this.acc.activeIds = 'toggle-3';*/
    const params = this.coreService._getParams();
    console.log(params);
    //this.coreService.loadParams().subscribe(paramsServer => params = paramsServer);
    console.log(params);
    this.installService.step_two(params, this.formStepTwo.value)
       .pipe(whileComponentNotDestroyed(this))
       .subscribe(
         result => {
           this.loader = false;
           if (result.success) {
             this.currentStep = '3';
             this.acc.activeIds = 'toggle-3';
             this.toast.success(this.translateService.instant('SETTING.Successfully'));
             this.currentStep = '3';
             this.acc.activeIds = 'toggle-3';
             this.installService.setStep('3');
             this.coreService._setParams(params);
           } else {
             this.toast.error(this.translateService.instant('SETTING.Error'));
           }

         },
         (error) => {
           console.log(error);
           this.loader = false;
           this.toast.error(this.translateService.instant('SETTING.Error'));
         });
  }

  finishInstall() {
    /* this.currentStep = '3';
     this.acc.activeIds = 'toggle-3';*/
    const params = this.coreService._getParams();
    this.installService.step_three(params)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          this.loader = false;
          if (result.success) {
            const params = this.coreService._getParams();
            console.log(params);
            this.coreService._setParams(params);
            localStorage.setItem('token', params.token);
            this.toast.success(this.translateService.instant('SETTING.Successfully'));
            this.installService.setStep('1');
            this.loader = true;
            setTimeout(() => {
              this.router.navigate(['/statistics/list']);
            }, 3000);
          } else {
            this.toast.error(this.translateService.instant('SETTING.Error'));
          }

        },
        (error) => {
          console.log(error);
          this.loader = false;
          this.toast.error(this.translateService.instant('SETTING.Error'));
        });
  }

  buildFormStep() {
    this.formStepOne = this.fb.group({
      host: ['localhost', Validators.required],
      user: ['', Validators.required],
      password: ['', Validators.required],
      name: ['', Validators.required],
      port: [3306, [Validators.required]],
      domen: ['http://api.unapps.ru', [Validators.required]],
      interpreter: ['http://interpetator.unapps.ru', [Validators.required]],
    });

    this.formStepTwo = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get formStepOneGet() { return this.formStepOne.controls; }
  get formStepTwoGet() { return this.formStepTwo.controls; }

}
