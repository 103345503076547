import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { whileComponentNotDestroyed } from 'src/app/shared/decorators/component-destroy-observer/component-destroy-observer';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TaskService } from 'src/app/pages/task/service/task.service';
import { HttpResponse } from '@angular/common/http';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../service/shared.service';
import { Client } from '../../../auth/model/client';
import { CoreService } from '../../../core/service/core.service';
import * as moment from 'moment';
import { LoginCloudComponent } from 'src/app/auth/login-cloud/login-cloud.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChanged = new EventEmitter<boolean>();
  total: number;
  user: any = {};
  menuSmall = false;
  menuMobileStatus = false;
  currentClient: Client;
  mobileDropdown = false;
  params = this.coreService._getParams();
  disabledCreateTask = false;

  constructor(private authService: AuthService,
              private toast: ToastrService,
              private taskService: TaskService,
              config: NgbTooltipConfig,
              private coreService: CoreService,
              private modalService: NgbModal,
              private sharedService: SharedService,
              private router: Router) {
    config.placement = 'bottom';
    this.sharedService.statusMenuMobile.subscribe(status => this.menuMobileStatus = status);
  }

  ngOnInit() {
    this.userInfo();
    this.loadTasks();
    this.loadCurrentClient();
  }


  userInfo() {
      this.authService.currentUser.subscribe(item => this.user = item);
  }
  logout() {
    this.authService.logout();
    this.toast.success('Успешный выход');
    location.reload();
  }
  change() {
    this.menuSmall = !this.menuSmall;
    this.onChanged.emit(this.menuSmall);
  }
  mobileMenu() {
    this.menuMobileStatus = !this.menuMobileStatus;
    this.mobileDropdown = false;
    this.sharedService.setStatusMenu(this.menuMobileStatus);
  }

  loadTasks() {
   const filter = {
      page: 1,
      sort: 'blog_url',
      size: 1,
      filter: {}
    };
   this.taskService.loadTask(filter)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        (result: HttpResponse<any>) => {
          this.total = Number(result.headers.get('X-Pagination-Total-Count'));
        },
        (error) => {
          console.log(error);

          /*error.foreach(item => {
            this.toastr.error(item.message, 'Завершено с ошибкой');
          });*/
        });
  }

  createTask() {
      this.disabledCreateTask = true;
      let source = [
          {
              Step: 1,
              Name: 'Шаг',
              InData: []
          },
          {
              Step: 2,
              Name: 'Шаг',
              InData: []
          }
      ];
      if (this.currentClient.interface_lang === 'EN') {
          source = [
              {
                  Step: 1,
                  Name: 'Step',
                  InData: []
              },
              {
                  Step: 2,
                  Name: 'Step',
                  InData: []
              }
          ];
      }

      const data = {
      client_id: this.user.client_id,
      name: `Задача ${this.total + 1}`,
      source: JSON.stringify(source)
    };
      if (this.currentClient.interface_lang === 'EN') {
        data.name = `Task ${this.total + 1}`;
    }
      this.loadTasks();
      this.taskService.createTask(data)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          this.disabledCreateTask = false;
          this.router.navigate(['/task/detail', result.id]);
        },
        (error) => {
          this.disabledCreateTask = false;
          console.log(error);
        });
  }

  loadCurrentClient() {
    this.authService.currentClient$.subscribe(item => this.currentClient = item);
  }

  getPurcent(sum, job) {
    let result = (job / sum) * 100;
    if (result > 100) {
      result = 100;
    }
    return result.toFixed(0);
  }

  showDropdown() {
    this.mobileDropdown = !this.mobileDropdown;
  }

  noLocalTimeZone(value) {
    return moment.unix(value).utc(false).format('DD-MM-YYYY');
  }

  openPopupLoginCloud() {
    const modalRef = this.modalService.open(LoginCloudComponent);
  }
}
