import { Deserializable } from 'src/app/shared/model/deserializable';
import { ProviderField } from 'src/app/pages/authorizations/model/provider-field';

export class ProviderConfig implements Deserializable {
  Callback: string;
  Extender: string;
  Fields: ProviderField[];
  Handler: string;
  Provider: string;
  Version: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if ( input.Fields ) {
      this.Fields = input.Fields.map(item => new ProviderField().deserialize(item) );
    }
    return this;
  }
}
