import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { InstallComponent } from 'src/app/install/install.component';
import { AppComponent } from './app.component';


const routes: Routes = [
  { path: '', component: AppComponent, data: {
      title: 'Главная'
    }},
  { path: 'login', component: LoginComponent, data: {
      title: 'Вход'
  }},
  { path: 'install', component: InstallComponent, data: {
      title: 'Установка'
  }},
  {
    path: 'apps',
    data: {title: 'Приложения'},
    loadChildren : () => import('./client/client.module').then(m => m.ClientModule),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
