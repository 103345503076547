import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { whileComponentNotDestroyed } from 'src/app/shared/decorators/component-destroy-observer/component-destroy-observer';
import { Router } from '@angular/router';
import { Client } from '../auth/model/client';
import { User } from '../auth/model/user';
import { CoreService } from '../core/service/core.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  params: any;
  constructor(private authService: AuthService,
              private coreService: CoreService,
              private toastr: ToastrService,
              private router: Router) {
    this.params = coreService._getParams();
  }
  menuSmall;
  user: User;
  ngOnInit() {
    this.loadCurrentClient();
    this.redirectFromUser();
    this.userInfo();
  }
  onChanged(menuSmall: any) {
    this.menuSmall = menuSmall;
  }

  redirectFromUser() {
    if (this.params.isBox === 'false' && !this.params.token) {
      this.router.navigate(['/login']);
    }
  }
  userInfo() {
    if (this.params.isBox === 'false' || this.params.isBox === 'true' && this.params.token) {
      this.authService.userInfo()
        .pipe(whileComponentNotDestroyed(this))
        .subscribe(
          result => {
            if (result.success) {
              this.user = new User().deserialize(result.data);
              this.authService._setCurrentUser(this.user);
            } else {
              this.authService.logout();
              this.router.navigate(['/login']);
            }
          },
          (error) => {
            if (this.params.isBox !== 'true') {
              this.authService.logout();
              this.router.navigate(['/login']);
            }
            /*error.foreach(item => {
              this.toastr.error(item.message, 'Завершено с ошибкой');
            });*/
          });
    }

  }

  loadCurrentClient() {
    if (this.params.isBox === 'false' || this.params.isBox === 'true' && this.params.token) {
      this.authService.currentClient()
        .pipe(whileComponentNotDestroyed(this))
        .subscribe(
          result => {
            const client = new Client().deserialize(result);
            this.authService._setCurrentClient(client);
          },
          (error) => {
            console.log(error);

            /*error.foreach(item => {
              this.toastr.error(item.message, 'Завершено с ошибкой');
            });*/
          });
    }

  }
}
