// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*http://api.devunapps.mlg.php.dev1.thecoders.ru*/
/*https://api-ru.unapps.io*/
/*http://api.ru-app.unapps.io*/
/*Api-ru.unapps.io*/
export const environment = {
  production: false,
  api: 'http://dev-api.unapps.io', // Ссылка на api backend
  cloudApi: 'http://dev-in.unapps.io', // ссылка на компилятор и тестер
  auth: 'https://dev-oauth.unapps.io', // ссылка на перенаправление oauth авторизации
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
