import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { CoreService } from '../../core/service/core.service';

@Injectable({
  providedIn: 'root'
})
export class InstallService {
  params: any;
  currentStep = new BehaviorSubject<string>('1');

  constructor(private http: HttpClient, private coreService: CoreService) {
    this.params = this.coreService._getParams();
  }

  step_one(params = {domen: ''}): Observable<any> {
    const url = `${params.domen}/install/step-one/`;
    return this.http.post<any>(url, params)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
      );
  }

  step_two(params, data): Observable<any> {
    const url = `${params.api}/install/step-two/`;
    return this.http.post<any>(url, data)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
      );
  }

  step_three(params): Observable<any> {
    const url = `${params.api}/front/finish-install`;
    return this.http.post<any>(url, params)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
      );
  }

  setStep(step: string = '1') {
    localStorage.setItem('step', step);
  }
  getStep() {
    return localStorage.getItem('step');
  }
}
