import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../service/shared.service';
import { AuthService } from '../../../auth/service/auth.service';
import { CoreService } from '../../../core/service/core.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  statusMenuMobile: boolean;
  client;
  params = this.coreService._getParams();

  constructor(private sharedService: SharedService, private authService: AuthService, private coreService: CoreService) { }

  ngOnInit() {
    this.sharedService.statusMenuMobile.subscribe(status => this.statusMenuMobile = status);
    this.authService.currentClient$.subscribe(item => this.client = item);
  }

  switchMenuMobile() {
    this.sharedService.setStatusMenu(false);
  }
}
