import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { whileComponentNotDestroyed } from '../../shared/decorators/component-destroy-observer/component-destroy-observer';
import { AuthService } from '../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-cloud',
  templateUrl: './login-cloud.component.html',
  styleUrls: ['./login-cloud.component.scss']
})
export class LoginCloudComponent implements OnInit {
  model = {
    username: '',
    password: ''
  };
  loader = false;
  constructor(public activeModal: NgbActiveModal,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private authService: AuthService) { }

  ngOnInit() {
  }
  closeModal() {
    this.activeModal.dismiss();
  }
  onSubmit() {
    this.loader = true;
    this.authService.loginCloud(this.model)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          if (result.success) {
            this.toastr.success(this.translateService.instant('AUTH.Successful_login'), this.translateService.instant('SETTING.Successfully'));
            this.closeModal();
            /*this.router.navigate(['/statistics/list']);*/
          } else {
            /*this.toastr.error(this.translateService.instant('SETTING.Error'), this.translateService.instant('AUTH.Enter_the_correct_data'));*/
          }
          this.loader = false;
        },
        (error) => {
          this.toastr.error(this.translateService.instant('SETTING.Error'), this.translateService.instant('AUTH.Enter_the_correct_data'));
          this.loader = false;
        });
  }
}
