import { Component, Input, OnInit } from '@angular/core';
import { whileComponentNotDestroyed } from '../../../shared/decorators/component-destroy-observer/component-destroy-observer';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../auth/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import {CoreService} from "../../../core/service/core.service";
import {Router} from "@angular/router";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TaskService} from "../../../pages/task/service/task.service";

@Component({
    selector: 'app-signup-popup',
    templateUrl: './signup-popup.component.html',
    styleUrls: ['./signup-popup.component.scss']
})
export class SignupPopupComponent implements OnInit {
    @Input() dataTask: any;

    loader = false;
    model: any = {
        termscond: false,
    };
    constructor(private cookieService: CookieService,
                private toastr: ToastrService,
                private coreService: CoreService,
                private router: Router,
                private taskService: TaskService,
                public activeModal: NgbActiveModal,
                private authService: AuthService) { }

    ngOnInit() {
        /*console.log(this.dataTask)*/
    }
    onSubmit() {
        this.loader = true;
        let terms = '0';
        if (this.model.termscond === true) {
            terms = '1';
        } else {
            terms = '0';
        }
        const data = {
            email: this.model.email,
            phone: this.model.phone,
            terms_cond: terms,
            username: this.model.username
        };

        this.model._pgdSid = this.cookieService.get('_pgdSid');
        this.authService.signup(data)
            .pipe(whileComponentNotDestroyed(this))
            .subscribe(
                result => {
                    if (result.success) {
                        this.toastr.success('Вы успешно зарегистрированы');
                        console.log(result.data.password_hash);
                        localStorage.setItem('token', result.data.password_hash);
                        console.log(result);
                        this.createTask(result.data);
                    } else {
                        this.toastr.error('Ошибка');
                        this.loader = false;
                    }

                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                    this.toastr.error('Ошибка');
                });
    }

    createTask(user) {
        this.dataTask.client_id = user.client_id;
        this.taskService.createTask(this.dataTask)
            .pipe(whileComponentNotDestroyed(this))
            .subscribe(
                result => {
                    this.loader = false;
                    this.toastr.success('Задача успешно создана, сейчас будет выполнен переход', 'Успешно');
                    this.activeModal.close();
                    this.router.navigate(['/task/detail', result.id]);
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Ошибка, создания задачи', 'Обратитесь в службу технической поддержки');
                });
    }
}
