import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';


const routes: Routes = [
  {path: 'signup', component: RegistrationComponent,  data: {
      title: 'Регистрация'
    }},
  {path: 'reset-password', component: PasswordResetComponent, data: {
      title: 'Восстановление пароля'
    }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
