import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Observable } from 'rxjs';
import { CoreService } from '../../core/service/core.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private coreService: CoreService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.coreService._getParams()) {
      if (this.coreService._getParams().isBox !== 'true') {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.coreService._getParams().token}`
          }
        });
      }
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer none`
        }
      });
    }


    return next.handle(request);
  }
}
