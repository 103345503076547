import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { CookieService } from 'ngx-cookie-service';
import { LoginCloudComponent } from './login-cloud/login-cloud.component';


@NgModule({
  declarations: [AuthComponent, LoginComponent, RegistrationComponent, PasswordResetComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    NgxMaskModule
  ],
  providers: [CookieService]
})
export class AuthModule { }
