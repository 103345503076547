import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../../auth/model/user';
import { environment } from '../../../environments/environment';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CoreService {

  public isBoxVersion = new BehaviorSubject<any>(true);
  public isLocalUser = new BehaviorSubject<any>(true);
  public params = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }
  _getLocalUser() {
    this.isLocalUser.getValue();
  }
  getStateChange(): Observable<any> {
    return this.params.asObservable();
  }
  _getParams() {
    return JSON.parse(localStorage.getItem('params'));
  }
  _setParams(value) {
    localStorage.setItem('params',  JSON.stringify(value));
  }
  _delParams() {
    localStorage.removeItem('params');
  }


  loadParams(params = {}): Observable<any> {
    const url = `/assets/config/config.json`;
    return this.http.get<any>(url, {
      params: {},
    }).pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error)),
        publishLast(),
        refCount()
    );
  }

  get disabledIsBox() {
    if (this._getParams().isBox === 'true') {
      if(!this._getParams().token) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    /*if (this._getParams().isBox && !this._getParams().token) {
      return true;
    } else if(!this._getParams().isBox) {
      return false;
    }*/
  }
}
