import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { Client } from '../model/client';
import { CoreService } from '../../core/service/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: false;
  public currentUser = new BehaviorSubject<any>(null);
  public currentClient$ = new BehaviorSubject<Client>(null);

  constructor(private http: HttpClient, private coreService: CoreService) {
  }

  _getCurrentUser(): User {
    return this.currentUser.getValue();
  }
  _setCurrentUser(user: any) {
    this.currentUser.next(user);
  }

  _setCurrentClient(client) {
    this.currentClient$.next(client);
  }

  _getCurrentClient() {
    return this.currentClient$.asObservable();
  }

  login(data, params): Observable<any> {
    const url = `${params.cloudBase}/auth`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        localStorage.setItem('token', result.data.token);
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  loginCloud(data): Observable<any> {
    let params = this.coreService._getParams();
    const url = `${params.api}/front/cloud-login`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        localStorage.setItem('token', result.data.token);
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  signup(data): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.api}/signup`;
    delete data.termscond;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  requestPasswordReset(data): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.api}/request-password-reset`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  passwordReset(data): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.api}/user-reset-password`;
    return this.http.post<any>(url, data, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }


  userInfo(): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.cloudBase}/user-info`;
    return this.http.post<any>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  currentClient(): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.cloudBase}/client/current`;
    return this.http.post<any>(url, {}, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  userFromAdmin(token: string): Observable<any> {
      let params = this.coreService._getParams();
    const url = `${params.api}/auth`;
    return this.http.post<any>(url, {auth_key: token}, {
      params: {},
    }).pipe(
      map(result => {
        return result;
      }),
      catchError(error => throwError(error)),
      publishLast(),
      refCount()
    );
  }

  logout() {
    localStorage.removeItem('token');
  }

  setToken(token: string = '') {
    localStorage.setItem('token', token);
  }
  getToken() {
    return localStorage.getItem('token');
  }
}
