import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../auth/model/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public statusMenuMobile = new BehaviorSubject<any>(false);
  constructor() { }
  _getStatusMenu(): User {
    return this.statusMenuMobile.getValue();
  }
  getStatusMenu(): Observable<any> {
    return this.statusMenuMobile.asObservable();
  }
  setStatusMenu(value: boolean) {
    this.statusMenuMobile.next(value);
  }
}
