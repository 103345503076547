import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { whileComponentNotDestroyed } from 'src/app/shared/decorators/component-destroy-observer/component-destroy-observer';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  model: any = {
    email: '',
    phone: ''
  };
  loader = false;
  private querySubscription: Subscription;
  token = '';
  step = '1';
  constructor(private authService: AuthService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private router: Router) { }

  ngOnInit() {
    this.querySubscription = this.route.queryParams.subscribe(
      (queryParam: any) => {
        this.token = queryParam['token'];
        if ( this.token ) {
          this.step = '2';
          this.model.token = this.token;
        } else {
          this.step = '1';
        }
      }
    );
  }
  onRequestPasswordSubmit() {
    if (this.model.email === '' && this.model.phone === '') {
      this.toastr.error(this.translateService.instant('AUTH.Error_recovery'), this.translateService.instant('AUTH.Required_fields'));
    } else {
      this.loader = true;
      this.authService.requestPasswordReset(this.model)
        .pipe(whileComponentNotDestroyed(this))
        .subscribe(
          result => {
            if (result.success) {
              this.toastr.success(this.translateService.instant('AUTH.Manual_of_letters'));
              this.loader = false;
            } else {
              this.toastr.error(this.translateService.instant('AUTH.Existing_data'), result.status);
              this.loader = false;
              Object.keys(result.data).map((objectKey, index) => {
                let value = result.data[objectKey];
                this.toastr.error(this.translateService.instant('SETTING.Error'), value);
              });
            }
          },
          (error) => {
            /*error.foreach(item => {
              this.toastr.error(item.message, 'Завершено с ошибкой');
            });*/
          });
    }

  }

  resetPasswordSubmit() {
    const lengthPassword = 6;
    if (this.model.password.length >= lengthPassword) {
      this.authService.passwordReset(this.model)
        .pipe(whileComponentNotDestroyed(this))
        .subscribe(
          result => {
            if (result.success) {
              this.toastr.success(this.translateService.instant('AUTH.Successful_password_change'), '');
              this.router.navigate(['/login']);
            } else {
              this.toastr.error(this.translateService.instant('AUTH.Error_restoring_password'), result.status);
            }
          },
          (error) => {

            /*error.foreach(item => {
              this.toastr.error(item.message, 'Завершено с ошибкой');
            });*/
          });
    } else {
      this.toastr.error(this.translateService.instant('AUTH.Error_restoring_password'), this.translateService.instant('AUTH.Error_length_password', {lengthPassword}));
    }

  }
}
