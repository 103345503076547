import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { whileComponentNotDestroyed } from '../../shared/decorators/component-destroy-observer/component-destroy-observer';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  constructor(private authService: AuthService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              private translateService: TranslateService,
              private router: Router) { }
  model: any = {
    termscond: false
  };
  loader = false;
  ngOnInit() {
  }
  onSubmit() {
    this.loader = true;
    let terms = '0';
    if (this.model.termscond === true) {
      terms = '1';
    } else {
      terms = '0';
    }
    const data = {
      email: this.model.email,
      phone: this.model.phone,
      terms_cond: terms,
      username: this.model.username
    };

    this.model._pgdSid = this.cookieService.get('_pgdSid');
    this.authService.signup(data)
      .pipe(whileComponentNotDestroyed(this))
      .subscribe(
        result => {
          if (result.success) {
            this.toastr.success(this.translateService.instant('AUTH.Successful_registration'), this.translateService.instant('AUTH.Manual_of_letters'));
            this.router.navigate(['/login']);
          } else {
            this.toastr.error(this.translateService.instant('AUTH.Existing_data'), result.message);
          }
          this.loader = false;
        },
        (error) => {
          /*console.log(error);*/
          this.loader = false;
          error.foreach(item => {
            this.toastr.error(item.message, this.translateService.instant('SETTING.Error'));
          });
        });
  }
}
